<template>
  <div style="padding: 15px">
    <error-snackbar
      v-if="showErrorSnackbar"
      :snackbarText="errorSnackbarText"
    />
    <v-row no-gutters align="center" style="margin-bottom: 17px">
      <v-icon color="black" @click="back" class="pointer">mdi-menu-left</v-icon
      >&nbsp;<span
        @click="back"
        style="
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.1em;
          color: #243949;
        "
        class="pointer"
        >Назад</span
      ></v-row
    >
    <div class="sectionBackground">
      <h3 class="sectionTitle">Основні параметри</h3>
      <div class="sectionLine" />
      <v-row no-gutters align="center">
        <v-col cols="4">
          <v-autocomplete
            outlined
            dense
            label="Виберіть маршрут"
            hide-details
            v-model="showCityId"
            style="border-radius: 10px"
            color="#E2E2E2"
            background-color="#FFF"
            height="48px"
            :items="Object.values(routes)"
            :item-text="
              (item) =>
                `${
                  item?.departure?.translations.find(
                    (translate) => translate.lang == 'ua'
                  )?.name
                } - ${
                  item?.destination?.translations.find(
                    (translate) => translate.lang == 'ua'
                  )?.name
                }`
            "
            :item-value="'id'"
            :error-messages="showCityIdError"
          ></v-autocomplete
        ></v-col>
        <v-col cols="3" style="padding: 0px 20px">
          <v-autocomplete
            outlined
            dense
            label="Виберіть валюту"
            hide-details
            v-model="currency"
            style="border-radius: 10px"
            color="#E2E2E2"
            background-color="#FFF"
            height="48px"
            :items="['грн', '$', 'zł']"
        /></v-col>
        <submit-button
          style="width: 160px; height: 48px !important"
          @click="showPrice"
          >Показати</submit-button
        >
      </v-row>
      <loader v-if="showLoader" />
      <v-col class="px-0 py-0 priceCard" v-else>
        <v-row no-gutters align="start" justify="start">
          <div style="width: max-content">
            <v-col style="margin-top: 105px">
              <v-row
                no-gutters
                align="center"
                justify="end"
                v-for="city in foreing_city"
                :key="city.station.city_uuid"
              >
                <v-row no-gutters align="center" justify="end">
                  <div
                    style="
                      height: 48px;
                      margin-bottom: 10px;
                      display: flex;
                      align-items: center;
                      justify-content: right;
                    "
                    class="pr-5"
                  >
                    <span
                      style="font-size: 18px; color: #085895; font-weight: 500"
                      >{{
                        city?.station?.translations.find(
                          (translate) => translate.lang == "ua"
                        )?.name
                      }}</span
                    >
                  </div>

                  <!-- <v-icon v-if="showCityId == city.id">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon> -->
                </v-row>
              </v-row>
            </v-col>
          </div>
          <v-col
            cols="10"
            xl="10"
            lg="9"
            md="8"
            class="d-flex py-0"
            v-if="cities"
          >
            <div
              v-for="city in ukraine_city"
              :key="city.station.city_uuid"
              style="width: 150px"
            >
              <v-row no-gutters align="center" justify="center" class="mb-3">
                <v-col class="py-0">
                  <div
                    style="
                      height: 100px;
                      display: flex;
                      align-items: end;
                      justify-content: center;
                      width: 150px;
                    "
                    class="pr-5 mb-5"
                  >
                    <span
                      style="font-size: 18px; color: #085895; font-weight: 500"
                      >{{
                        city?.station?.translations.find(
                          (translate) => translate.lang == "ua"
                        )?.name
                      }}</span
                    >
                  </div>
                  <div
                    :style="
                      city.station.city_uuid == price.from_city_id
                        ? 'height: 40px; margin-bottom: 20px; width: 150px;'
                        : city.station.city_uuid == price.to_city_id
                        ? 'height: 40px; margin-bottom: 20px;  width: 150px;'
                        : ''
                    "
                    v-for="price in cities.prices"
                    :key="price.city_uuid"
                    class="pr-5"
                  >
                    <v-text-field
                      v-if="
                        city.station.city_uuid == price.from_city_id
                          ? city.station.city_uuid == price.from_city_id
                          : city.station.city_uuid == price.to_city_id
                      "
                      height="48px"
                      outlined
                      dense
                      v-model="price.price"
                      label="Ціна"
                      type="number"
                      hide-spin-buttons
                      style="border-radius: 10px"
                      color="#E2E2E2"
                      background-color="#FFF"
                      :success="price.price > 0"
                    />
                  </div>
                </v-col>
                <!-- <v-icon v-if="showCityId == city.id">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon> -->
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row
            no-gutters
            justify="center"
            style="margin-top: 20px"
            v-if="ukraine_city.length > 0"
          >
            <submit-button
              style="
                margin-bottom: 16px;
                height: 48px !important;
                font-size: 20px;
                font-weight: 700;
              "
              @click="savePrices"
              :style="
                cities.prices.findIndex((price) => price.price == 0) == -1
                  ? ''
                  : 'pointer-events:none; background-color: silver!important'
              "
              >Зберегти зміни</submit-button
            >
            <cancel-btn
              text="Скасувати"
              @click="(showLoader = true), getPrice(showCityId)"
            />
          </v-row>
        </v-col>
      </v-col>
    </div>
  </div>
</template>
  
  <script>
import routesService from "../../../../requests/Admin/routesService";
import requestFormData from "../../../../requests/requestFormData";
import Loader from "../../../UI/Loader.vue";
import errorSnackbar from "../../../UI/Snackbars/errorSnackbar.vue";
import submitButton from "../../../UI/Buttons/submitButton.vue";
import cancelBtn from "../../../UI/Buttons/cancelBtn.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  components: { Loader, errorSnackbar, submitButton, cancelBtn },
  data: () => ({
    routes: [],
    cities: [],
    foreing_city: [],
    ukraine_city: [],
    showCityId: "",
    errorSnackbarText: "",
    currency: "",
    min_price: 100,
    showErrorSnackbar: false,
    showLoader: false,
    page: 1,
  }),
  validations: {
    showCityId: {
      required,
    },
  },
  props: {
    route_id: {
      require: true,
    },
  },
  mounted() {
    this.getRoutes();
    this.$vuetify.goTo(0, 0);
  },
  methods: {
    back() {
      this.$router.back();
    },
    showPrice() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.showLoader = true;
        this.getPrice(this.showCityId);
      }
    },
    async getRoutes() {
      let response = await routesService.getRouteForAdmin(this.page);
      this.routes.push(...response.data);
      while (this.page < response.pagination.meta.last_page) {
        this.page++;
        this.getRoutes();
      }
      if (this.$route.name == "pricesEditComponent") {
        this.showCityId = this.$route.params.route_id;
        this.getPrice(this.showCityId);
      }
    },
    async getPrice(id) {
      let response = await routesService.getRouteAdmin(id);
      this.foreing_city = response.data.cities.filter(
        (city) => city.type == "Foreign"
      );
      this.foreing_city.sort((a, b) => a.sequence - b.sequence);
      //this.foreing_city = this.removeDuplicates(this.foreing_city, "station.city_uuid");
      this.ukraine_city = response.data.cities.filter(
        (city) => city.type == "Ukraine"
      );
      this.ukraine_city.sort((a, b) => a.sequence - b.sequence);
      //this.ukraine_city = this.removeDuplicates(this.ukraine_city, "station.city_uuid");
      this.cities = response.data;
      this.cities.prices.forEach(
        (city, index) => (this.cities.prices[index].price = city.price)
      );
      this.showLoader = false;
    },
    async savePrices() {
      let validPrice = true;
      // this.cities.prices.forEach((city) => {
      //   if (parseInt(city.price) < this.min_price) {
      //     validPrice = false;
      //   }
      // });
      this.validPrice = true;
      if (validPrice) {
        let prices = [];
        this.showLoader = true;
        this.cities.prices.forEach((city) => {
          prices.push({
            id: city.id,
            route_id: this.showCityId,
            from: city.from_city_id,
            to: city.to_city_id,
            price: city.price * 100,
          });
        });
        let data = {
          prices: prices,
        };
        let form = requestFormData.jsonToFormData(data);
        await routesService.savePrices(this.cities.id, form).then(() => {
          setTimeout(() => {
            this.showLoader = false;
          }, 100);
        });
      } else {
        this.errorSnackbarText = `Ціна не може бути меншою за ${this.min_price}`;
        this.showErrorSnackbar = true;
        setTimeout(() => {
          this.showErrorSnackbar = false;
        }, 3000);
      }
    },
    removeDuplicates(array, key) {
      return array.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.station[key] === item.station[key])
      );
    },
  },
  computed: {
    showCityIdError() {
      const errors = [];
      if (!this.$v.showCityId.$dirty) {
        return errors;
      }
      !this.$v.showCityId.required && errors.push("");
      return errors;
    },
  },
  watch: {
    // showCityId: {
    //   handler() {
    //     this.showLoader = true;
    //     this.getPrice(this.showCityId);
    //   },
    // },
    route_id: {
      handler() {
        this.getPrice(this.route_id);
      },
    },
  },
};
</script>
  
  <style scoped>
.priceCard {
  overflow: scroll;
  max-width: 100vw;
  overflow-y: hidden;
}
.saveBtn {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #085895;
  margin-bottom: 20px;
}
.activeDropdownItem {
  background: rgba(8, 88, 149, 0.1);
  border-radius: 10px;
}
.dropdownItem {
  padding: 5px 10px 5px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #243949;
  width: 100%;
  text-align: left;
}
.dropdownItem:hover {
  background: rgba(8, 88, 149, 0.1);
  border-radius: 10px;
  transition: 0.3s ease-in-out;
}
</style>